// Export start {{
//$primary: #b8d3d9;
$primary: #7aa44e;
// Exports end }}

// Custom start {{
$enable-rounded: true;
$border-radius: 0.8rem;
$border-radius-lg: 0.8rem;
$border-radius-sm: 0.8rem;
$font-family-sans-serif: Quicksand,"ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","メイリオ","Meiryo",sans-serif;
$font-family-monospace:  SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
$min-contrast-ratio: 1.5; // default 4.5
// Custom end }}
