body {
    overflow-y: scroll;
}
.modal {
  background: rgba(0, 0, 0, 0.7);
}

.term_of_service.alert-secondary {
  overflow-y: scroll;
  height: 10em;
}

.term_of_service h1 {
  font-size: 1.5em;
}

.term_of_service h2 {
  font-size: 1.3rem;
}
